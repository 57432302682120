import React from 'react';
import { Button as GrommetButton } from 'grommet';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colorConvert from 'services/color-convert';

const StyledButton = styled(GrommetButton)`
  padding: 12px 15px;
  &:hover {
    background: ${({ theme }) =>
      colorConvert.rgba(theme.global.colors.brand[theme.mode], 0.85)};
  }
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #fff;
`;

const Button = ({ text, handleClick, ...props }) => {
  return (
    <StyledButton
      primary
      label={<Text>{text}</Text>}
      align="center"
      justify="center"
      pad="small"
      onClick={handleClick}
      {...props}
    />
  );
};

Button.defaultProps = {
  handleClick: null,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default Button;
