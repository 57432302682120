import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Styled = styled.div`
  display: block;
  padding: ${({ padding }) => padding || 'inherit'};
  margin: ${({ margin }) => margin || 'inherit'};
`;

const Box = ({ padding, margin, ...props }) => {
  return <Styled padding={padding} margin={margin} {...props} />;
};

Box.defaultProps = {
  padding: 0,
  margin: 0,
};

Box.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Box;
