import styled from 'styled-components';

const BreadcrumbLink = styled.span`
  a {
    color: black;
  }

  &::after {
    content: ' > ';
  }
`;

export default BreadcrumbLink;
