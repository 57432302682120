export const makeFieldProps = type => {
  switch (type) {
    case 'date':
      return {
        validate: {
          regexp: RegExp('/^(d{3})sd{3}-d{4}$/'),
          message: 'Must match the format.',
        },
        mask: [
          { fixed: '(' },
          {
            length: 3,
            regexp: /^[1-9]{1,3}$/,
            placeholder: 'xxx',
          },
          { fixed: ')' },
          { fixed: ' ' },
          {
            length: 3,
            regexp: /^[0-9]{1,3}$/,
            placeholder: 'xxx',
          },
          { fixed: '-' },
          {
            length: 4,
            regexp: /^[0-9]{1,4}$/,
            placeholder: 'xxxx',
          },
        ],
      };
    case 'email':
      return {
        validate: {
          regexp: RegExp('/^.+@.+$/'),
          message: 'Must be a valid email',
          status: 'error',
        },
        mask: [
          {
            regexp: /^[\w\-_.]+$/,
            placeholder: 'example',
          },
          { fixed: '@' },
          {
            regexp: /^[\w-]+$/,
            placeholder: 'site',
          },
          { fixed: '.' },
          {
            regexp: /^[\w]+$/,
            placeholder: 'com',
          },
        ],
      };
    case 'tel':
      return {
        validate: {
          regexp: RegExp('/^(d{3})sd{3}-d{4}$/'),
          message: 'Must match the format.',
        },
        mask: [
          { fixed: '(' },
          {
            length: 3,
            regexp: /^[1-9]{1,3}$/,
            placeholder: 'xxx',
          },
          { fixed: ')' },
          { fixed: ' ' },
          {
            length: 3,
            regexp: /^[0-9]{1,3}$/,
            placeholder: 'xxx',
          },
          { fixed: '-' },
          {
            length: 4,
            regexp: /^[0-9]{1,4}$/,
            placeholder: 'xxxx',
          },
        ],
      };

    default:
      return {};
  }
};
