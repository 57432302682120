import { Box } from 'grommet';
import styled from 'styled-components';

export const ActivityContainer = styled.div`
  width: 100%;
`;

export const ActivityBox = styled(Box)`
  background: white;
  border-bottom: 1px solid rgba(151, 151, 151, 0.35);
  display: flex;
  padding: 1.5rem;
  margin: 0;
  flex-direction: row;
`;

export const NameContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Name = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: #3c3b3b;
`;

export const TimeAgo = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  margin-top: 0.2rem;
  color: #989696;
`;

export const Activity = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  color: #3c3b3b;
`;

export const Circle = styled.div`
  height: 41px;
  width: 41px;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  display: inline-block;
  opacity: 0.33;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 50%;
  }
`;

export const NoActivity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserManageButton = styled.button`
  padding: 0.5rem 2rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  border: ${props => `solid 1px ${props.backgroundColor}`};
  border-radius: 10px;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
`;
