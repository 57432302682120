import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import styled from 'styled-components';

import { /* Input,*/ Label, inputStyles, getWrapper } from './Input';

const StyledMaskedInput = styled(MaskedInput)`
  background: transparent;
  border-radius: 6px;
  border-style: solid;
  border: 1px solid #cccccc90;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin: 6px 0;
  max-width: 400px;
  outline: none;
  padding: 9px;
  width: 100%;
  ${inputStyles}
  &:focus {
    outline-offset: -2px;
    border: 0;
  }
`;

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, form, label, ...inputProps }) => {
  const Wrapper = getWrapper(form);

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  // TODO wrap the custom Input prop here, see https://github.com/text-mask/text-mask/tree/master/react#customize-rendered-input-component
  // return (
  //   <MaskedInput
  //     render={(ref, props) => <Input forwardRef={ref} {...props} />}
  //     mask={currencyMask}
  //     {...inputProps}
  //   />
  // );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <StyledMaskedInput mask={currencyMask} {...inputProps} />
    </Wrapper>
  );
};

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
};

CurrencyInput.propTypes = {
  inputMode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};

export default CurrencyInput;
