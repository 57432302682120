import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'inherit'};
  align-items: ${({ align }) => align || 'inherit'};
  flex-wrap: ${({ wrap }) => wrap && 'wrap'};
  flex-direction: ${({ direction }) => direction || 'inherit'};
`;

const Flex = ({ ...props }) => {
  return <Styled {...props} />;
};

export default Flex;
