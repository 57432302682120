import styled from 'styled-components';

const Link = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: rgba(19, 124, 53, 0.95);
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid rgba(19, 124, 53, 0.95);
  }
`;

export default Link;
