import React from 'react';
import {
  TextInput as GrommetTextInput,
  MaskedInput as GrommetMaskedInput,
  FormField as GrommetFormField,
} from 'grommet';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { makeFieldProps } from './utils';

export const inputStyles = css`
  margin: 6px 0;
  font-size: 16px;
  font-weight: 500;
  max-width: 400px;
  border-radius: 3px;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 0 5px;
`;

export const getWrapper = form => {
  if (form) return GrommetFormField;
  return 'div';
};

const StyledTextInput = styled(GrommetTextInput)`
  ${inputStyles}
`;

const StyledMaskedInput = styled(GrommetMaskedInput)`
  ${inputStyles}
`;

const Input = ({ label, type, form, mask, forwardRef, ...rest }) => {
  const Component = (mask => {
    if (mask) return StyledMaskedInput;
    return StyledTextInput;
  })(mask);

  const fieldProps = makeFieldProps(type);
  const Wrapper = getWrapper(form);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Component
        placeholder={label}
        type={type}
        ref={forwardRef}
        {...fieldProps}
        {...rest}
      />
    </Wrapper>
  );
};

Input.defaultProps = {
  mask: false,
  form: false,
  label: '',
  type: 'text',
};

Input.propTypes = {
  mask: PropTypes.bool,
  form: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
